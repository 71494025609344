import "./src/styles/index.css"
import persistQueryParams from "./utils/persist-query-params"
export const onRouteUpdate = ({ location, prevLocation }) => {
  const search = persistQueryParams(location, prevLocation)

  if (search) {
    location.search = search
    window.history.replaceState({}, null, `${location.pathname}?${search}`)
  }
}

const addScript = async (url) => {
  const script = document.createElement("script")
  script.src = url
  document.body.appendChild(script)
}
const initScript = url => {
  const script = document.createElement("script")
  script.type = 'text/javascript';
  let code = `sbjs.init({
    domain: '${url}',
    lifetime: 0,
    session_length: 0,
    timezone_offset: 8,
  });`
  script.appendChild(document.createTextNode(code))
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    /* initScript(window.location.hostname) */
    console.log('onLoad')
  }

  document.addEventListener('DOMContentLoaded', function() {
    console.log('DOMContentLoaded');
 }, false);
}