const queryString = require(`query-string`)

const targetQueryParams = [
  `gclid`,
  `adgroupid`,
  `campaignid`,
  `targetid`,
  `loc_interest_ms`,
  `loc_physical_ms`,
  `matchtype`,
  `device`,
  `creative`,
  `keyword`,
  `placement`,
  `adposition`,
  `network`,
  `utm_source`,
  `utm_medium`,
  `utm_campaign`,
  `utm_content`,
  `utm_context`,
  `utm_term`,
  `message`,
  `coupon`,
  `offer`,
  `zipCode`,
  `msclkid`,
]

module.exports = (newLocation, oldLocation) => {
  if (!oldLocation) {
    return null
  }

  const oldSearch = queryString.parse(oldLocation.search)
  const newSearch = queryString.parse(newLocation.search)

  // Filter old search to targeted values
  const filteredOldSearch = Object.keys(oldSearch)
    .filter((key) => targetQueryParams.includes(key))
    .reduce((obj, key) => {
      obj[key] = oldSearch[key]
      return obj
    }, {})

  const combinedNewSearch = {
    ...filteredOldSearch,
    ...newSearch,
  }

  return queryString.stringify(combinedNewSearch)
}
